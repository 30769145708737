import validator from "validator";

export const _validator = {
  required: (value, fieldName = '字段') => {
    if (!validator.isEmpty(value, { ignore_whitespace: true })) return true;
    return {
      type: 'required',
      message: `${fieldName}不能为空`,
    };
  },
  mobile: (value, fieldName = '手机号') => {
    if (validator.isMobilePhone(value, "zh-CN")) return true;
    return {
      type: 'mobile',
      message: `${fieldName}格式不正确`,
    };
  },
  email: (value, fieldName = '邮箱') => {
    if (validator.isEmail(value)) return true;
    return {
      type: 'email',
      message: `${fieldName}格式不正确`,
    };
  },
  password: (value, fieldName = '密码') => {
    if (value && value.trim().length >= 6 && /\d/.test(value) && /[a-zA-Z]/.test(value)) return true;
    return {
      type: 'password',
      message: `${fieldName}长度至少6位，至少包含数字和字母`,
    }
  },
  confirmPassword: (value, pid) => {
    const p = document.querySelector(`input#${pid}`);
    if (p && p.value === value) return true;
    return {
      type: 'confirmPassword',
      message: `两次密码输入不一致`,
    }
  }
};
