import { Controller } from "stimulus";
import { findParent, toggleClass } from "../utils";

export default class extends Controller {
  connect() { }

  toggleExpanded = (e) => {
    const tr = findParent(e.target, "tr", this.element);
    if (tr) toggleClass(tr, ["table-expanded"]);
  };

  disconnect() { }
}
