import Swal from "sweetalert2/dist/sweetalert2.js";

const getCustomClass = (type = 'info') => ({
  confirmButton: "btn btn-sm btn-primary me-2",
  cancelButton: "btn btn-sm btn-secondary ms-2",
  title: "fs-7",
  icon: `alert-icon alert-icon-${type}`,
});

const width = '30%';

export function confirm({ title = "", icon = "info", callback = null } = {}) {
  Swal.fire({
    title,
    icon,
    showCancelButton: true,
    showDenyButton: false,
    showConfirmButton: true,
    cancelButtonText: "取消",
    confirmButtonText: "确定",
    width,
    customClass: getCustomClass(icon),
    buttonsStyling: false,
  }).then((result) => {
    if (callback) callback(result.isConfirmed);
  });
}

export function alert({ position = "center", title = "", icon = "info", confirmButtonText = '确定' } = {}) {
  Swal.fire({
    position,
    icon,
    title,
    showConfirmButton: true,
    width,
    customClass: getCustomClass(icon),
    buttonsStyling: false,
    confirmButtonText,
  });
}
