import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['archiveRadio']
  
  connect() { }

  finalRadioChange = (e) => {
    if (e.target.value === 'true') {
      this.archiveRadioTargets.forEach(item => {
        item.disabled = true;
        item.checked = item.value === 'true';
      });
    } else {
      this.archiveRadioTargets.forEach(item => {
        item.disabled = false;
      });
    }
  }

  disconnect() { }
}
