// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const importAll = (r) => r.keys().map(r);
importAll(require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/i));

import "core-js/stable";
import "regenerator-runtime/runtime";
import "@stimulus/polyfills";

import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "@coreui/coreui";
import "channels";
import "jsoneditor/dist/jsoneditor.css";
import "./darktheme.css";
import "../../assets/stylesheets/application";
import "../controllers";
import { overrideRailsUjs, expandTurbo, startAjaxListener } from "../utils";

Rails.start();
ActiveStorage.start();

overrideRailsUjs(Rails);
expandTurbo(Turbo);
startAjaxListener();
