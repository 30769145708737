import { Controller } from "stimulus";
import { Popover } from "@coreui/coreui";

export default class extends Controller {
  connect() {
    new Popover(this.element, {
      container: "body",
    });
  }

  disconnect() {}
}
