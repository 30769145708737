import { Controller } from "stimulus";
import { Modal } from "@coreui/coreui";
import { findParent, myAjax, htmlStr2Dom, alert } from "../utils";

export default class extends Controller {
  static targets = ["scopeOption", "scopeModal", "list"];
  static values = {
    templateUrl: String,
  };

  scopeModal = null;

  connect() {
    this.scopeModal = new Modal(this.scopeModalTarget, {
      backdrop: "static",
      keyboard: false,
      focus: true,
    });
    this.resetScopeOptionTargets();
  }

  confirm = () => {
    const checkIds = this.scopeOptionTargets
      .filter((scope) => scope.checked)
      .map((scope) => scope.value);
    const scopeIds = this.getCurrentScopeIds();
    const removeIds = scopeIds.filter((item) => !checkIds.includes(item));
    const newIds = checkIds.filter((item) => !scopeIds.includes(item));
    removeIds.forEach((id) => this.removeByScopeId(id));
    this.addByScopeIds(newIds);
    this.hideScopeModal();
  };

  cancel = () => {
    this.hideScopeModal();
  };

  resetScopeOptionTargets = () => {
    const scopeIds = this.getCurrentScopeIds();
    this.scopeOptionTargets.forEach((scope) => {
      scope.checked = scopeIds.includes(scope.value);
    });
  };

  getCurrentScopeIds = () => {
    return Array.from(
      this.element.querySelectorAll(
        "input[type='hidden'][name*='[business_scope_id]']"
      )
    )
      .filter((elem) => {
        const destroy = elem.parentNode.querySelector(
          "input[type='checkbox'][name*='[_destroy]']"
        );
        return !destroy.checked;
      })
      .map((elem) => elem.value);
  };

  addByScopeIds = (scopeIds) => {
    if (
      Array.isArray(scopeIds) &&
      scopeIds.length > 0 &&
      this.templateUrlValue &&
      this.hasListTarget
    ) {
      myAjax({
        url: this.templateUrlValue,
        type: "GET",
        data: scopeIds.map((id) => `business_scope_ids[]=${id}`).join("&"),
        success: (_data, _status, response) => {
          this.listTarget.append(htmlStr2Dom(response.responseText));
          this.fireChangeEvent();
        },
      });
    } else {
      this.fireChangeEvent();
    }
  };

  removeByScopeId = (scopeId) => {
    Array.from(
      this.element.querySelectorAll(
        `input[type='hidden'][name*='[business_scope_id]'][value='${scopeId}']`
      )
    )
      .filter((elem) => {
        const destroy = elem.parentNode.querySelector(
          "input[type='checkbox'][name*='[_destroy]']"
        );
        return !destroy.checked;
      })
      .forEach((elem) => {
        const rowElem = findParent(elem, "tr", this.element);
        if (rowElem) {
          const idElem = elem.parentNode.querySelector(
            "input[type='hidden'][name*='[id]']"
          );
          if (idElem.value) {
            const destroy = elem.parentNode.querySelector(
              "input[type='checkbox'][name*='[_destroy]']"
            );
            destroy.checked = true;
            rowElem.style.display = "none";
          } else {
            rowElem.remove();
          }
        }
      });
  };

  showScopeModal = () => {
    this.resetScopeOptionTargets();
    this.scopeModal.show();
    alert({ title: '请填写本公司主要经营范围，以及本次实际合作的业务类型' });
  };

  hideScopeModal = () => {
    this.scopeModal.hide();
  };

  fireChangeEvent = () => {
    document.dispatchEvent(new Event('businessScopes:change'));
  };

  disconnect() {}
}
