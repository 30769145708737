import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "businessScopeInput",
    "businessScopeSelect",
    "productTypeInput",
    "productTypeSelect",
    "unitNameInput",
    "unitNameSelect",
    "offerInput",
  ];
  static values = {
    businessScopes: Array,
    businessScopeTargetId: String,
    businessScopeIds: Array,
  };

  businessScopeIds = [];

  connect() {
    this.businessScopeIds = this.businessScopeIdsValue || [];
    this.resetAll();
  }

  setBusinessScopeSelectOptions = () => {
    this.businessScopeSelectTarget.innerHTML = "";
    const options = this.businessScopesValue
      .filter(
        (item) =>
          Array.isArray(this.businessScopeIds) && this.businessScopeIds.includes(item.id)
      )
      .map((item) => {
        const optionElem = document.createElement("option");
        optionElem.textContent = item.title;
        optionElem.value = item.title;
        optionElem.selected =
          item.title === this.businessScopeInputTarget.value;
        return optionElem;
      });
    this.businessScopeSelectTarget.append(...options);
    this.businessScopeInputTarget.value = this.businessScopeSelectTarget.value;
  };

  setProductTypeSelectOptions = () => {
    this.productTypeSelectTarget.innerHTML = "";
    const businessScope = this.businessScopesValue.find(
      (item) => item.title === this.businessScopeInputTarget.value
    );
    if (businessScope) {
      if (
        Array.isArray(businessScope.quotation_productions) &&
        businessScope.quotation_productions.length > 0
      ) {
        this.productTypeInputTarget.classList.add("d-none");
        this.productTypeSelectTarget.classList.remove("d-none");
        const options = businessScope.quotation_productions.map((item) => {
          const optionElem = document.createElement("option");
          optionElem.textContent = item.title;
          optionElem.value = item.title;
          optionElem.selected =
            item.title === this.productTypeInputTarget.value;
          return optionElem;
        });
        this.productTypeSelectTarget.append(...options);
        this.productTypeInputTarget.value = this.productTypeSelectTarget.value;
      } else {
        this.productTypeInputTarget.classList.remove("d-none");
        this.productTypeSelectTarget.classList.add("d-none");
      }
    } else {
      this.productTypeInputTarget.classList.add("d-none");
      this.productTypeSelectTarget.classList.add("d-none");
    }
  };

  setUnitNameSelectOptions = () => {
    const businessScope = this.businessScopesValue.find(
      (item) => item.title === this.businessScopeInputTarget.value
    );
    this.unitNameSelectTarget.innerHTML = "";
    if (businessScope) {
      if (
        Array.isArray(businessScope.quotation_productions) &&
        businessScope.quotation_productions.length > 0
      ) {
        const production = businessScope.quotation_productions.find(
          (item) => item.title === this.productTypeInputTarget.value
        );
        const options = production.units.map((item) => {
          const optionElem = document.createElement("option");
          optionElem.textContent = item;
          optionElem.value = item;
          optionElem.selected = item === this.unitNameInputTarget.value;
          return optionElem;
        });
        this.unitNameSelectTarget.append(...options);
      } else {
        const options = businessScope.quotation_units.map((item) => {
          const optionElem = document.createElement("option");
          optionElem.textContent = item;
          optionElem.value = item;
          optionElem.selected = item === this.unitNameInputTarget.value;
          return optionElem;
        });
        this.unitNameSelectTarget.append(...options);
      }
    }
    this.unitNameInputTarget.value = this.unitNameSelectTarget.value;
    this.setOfferInputStatus();
  };

  setOfferInputStatus = () => {
    if (this.unitNameInputTarget.value === '按实际项目议价') {
      if (this.hasOfferInputTarget) {
        this.offerInputTarget.readOnly = true;
        this.offerInputTarget.value = '0.0';
      }
    } else {
      if (this.hasOfferInputTarget) {
        this.offerInputTarget.readOnly = false;
      }
    }
  };

  businessScopeChange = () => {
    this.businessScopeInputTarget.value = this.businessScopeSelectTarget.value;
    this.productTypeInputTarget.value = "";
    this.setProductTypeSelectOptions();
    this.setUnitNameSelectOptions();
  };

  productTypeChange = () => {
    this.productTypeInputTarget.value = this.productTypeSelectTarget.value;
    this.setUnitNameSelectOptions();
  };

  unitNameChange = () => {
    this.unitNameInputTarget.value = this.unitNameSelectTarget.value;
    this.setOfferInputStatus();
  };

  resetAll = () => {
    this.setBusinessScopeSelectOptions();
    this.setProductTypeSelectOptions();
    this.setUnitNameSelectOptions();
  };

  disconnect() { }
}
