import { Controller } from "stimulus";
import { checkPartnerExist, obj2SearchParams, alert } from '../utils'

export default class extends Controller {
  static targets = ['category', 'nodesignOptional'];
  static values = {
    natureId: Number,
    id: Number,
  }

  connect() {
    if (this.hasCategoryTarget) {
      this.categoryTarget.dispatchEvent(new Event('change'));
    }
  }

  categoryChange = (e) => {
    const value = Number(e.target.value);
    this.nodesignOptionalTargets.forEach(item => {
      if (value === 10) {
        item.classList.add('required-label');
      } else {
        item.classList.remove('required-label');
      }
    });
  }

  checkUnique = (e) => {
    const fieldName = e.target.dataset.fieldName;
    const fieldValue = e.target.value.trim();
    if (fieldValue && this.natureIdValue) {
      const data = { [fieldName]: fieldValue, 'nature_id': this.natureIdValue };
      if (this.idValue) data['id'] = this.idValue;
      checkPartnerExist(obj2SearchParams(data), (res) => {
        if (res.existed) {
          alert({ title: res.message, icon: 'error' });
        } else {
          if (fieldName === 'title') this.setEmptyBankAccountName(fieldValue);
        }
      });
    }
  }

  setEmptyBankAccountName = (accountName) => {
    if (!accountName) return;
    const accountNameInputs = document.querySelectorAll('input[id^=partner_bank_accounts_attributes][id$=account_name]');
    accountNameInputs.forEach(elem => {
      const value = elem.value && elem.value.trim();
      if (!value) elem.value = accountName;
    });
  }

  validateFields = (e) => {
    const url = e.submitter.getAttribute('formaction');
    if (url && (url.includes('status=1') || url.includes('status=2'))) {
      const form = e.target;
      const formData = new FormData(form);
      const data = Object.fromEntries(formData.entries());
      const title = data['partner[title]'];
      const bankNameKeys = Object.keys(data).filter(key => /^partner\[bank_accounts_attributes\]\[\d+\]\[account_name\]$/.test(key));

      for (let i = 0; i < bankNameKeys.length; i++) {
        const key = bankNameKeys[i];
        if (data[key] !== title) {
          if (!confirm('银行账号户名与合作商名称存在不一致情况，确定要继续提交么？')) {
            e.preventDefault();
          }
          break;
        }
      }
    }
  }

  disconnect() {}
}
