import SparkMD5 from "spark-md5";

export function getFileMd5({ file, success = null, error = null }) {
  let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
    chunkSize = 2 * 1024 ** 2,                             // Read in chunks of 2MB
    chunks = Math.ceil(file.size / chunkSize),
    currentChunk = 0,
    spark = new SparkMD5.ArrayBuffer(),
    fileReader = new FileReader(),
    fileChunks = [];

  fileReader.onload = function (e) {
    console.log('read chunk nr', currentChunk + 1, 'of', chunks);
    spark.append(e.target.result);                   // Append array buffer
    currentChunk++;

    if (currentChunk < chunks) {
      loadNext();
    } else {
      const md5 = spark.end();
      if (success) success(md5, fileChunks);
    }
  };

  fileReader.onerror = function () {
    console.warn('oops, something went wrong.');
    if (error) error('计算出错了');
  };

  function loadNext() {
    const start = currentChunk * chunkSize,
      end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

    const fileChunk = blobSlice.call(file, start, end);
    fileChunks.push(fileChunk);

    fileReader.readAsArrayBuffer(fileChunk);
  }

  loadNext();
}

export function file_h_size(size) {
  const size_units = [
    { size: 1024, unit: 'B' },
    { size: 1024 ** 2, unit: 'KB' },
    { size: 1024 ** 3, unit: 'MB' },
    { size: 1024 ** 4, unit: 'GB' },
    { size: 1024 ** 5, unit: 'TB' },
  ];
  const size_unit = size_units.find(item => size < item.size);
  if (!size_unit) return '未知';
  if (size_unit.size > 1024 ** 3) return `${(size / size_unit.size * 1024).toFixed(2)}${size_unit.unit}`;
  return `${(size / size_unit.size * 1024).toFixed(0)}${size_unit.unit}`
}

export function file_h_time(speed, total) {
  if (!speed) return '未知';
  const time_units = [
    { time: 60, unit: '秒' },
    { time: 60 ** 2, unit: '分钟' },
  ];
  const seconds = Math.round(total / speed);
  console.log(seconds);
  const time_unit = time_units.find(item => seconds < item.time);
  if (time_unit) return `${Math.round(seconds / time_unit.time * 60)}${time_unit.unit}`;
  return `${(seconds / 60 / 60).toFixed(1)}${time_unit.unit}`;
}

export class FileUploadProgress {
  constructor({ title, max, value, infos }) {
    this.container = this.createContainer();
    this.title = this.createTitle(title);
    this.infos = this.createInfos(infos);
    this.progress = this.createProgress(max, value);
    this.container.appendChild(this.title);
    this.container.appendChild(this.infos);
    this.container.appendChild(this.progress);
    document.body.appendChild(this.container);
    this.bodyOverflowHidden = document.body.style.overflow === 'hidden';
    if (!this.bodyOverflowHidden) document.body.style.overflow = 'hidden';
  }

  createContainer() {
    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.bottom = '0';
    container.style.right = '0';
    container.style.display = 'flex';
    container.style.flexDirection = 'column';
    container.style.justifyContent = 'center';
    container.style.alignItems = 'center';
    container.style.background = 'rgba(0, 0, 0, 0.6)';
    container.style.gap = '10px';
    container.style.zIndex = '9999';
    return container;
  }

  createTitle(text) {
    const title = document.createElement('div');
    title.style.color = 'white';
    title.style.fontSize = '16px';
    title.textContent = text;
    return title;
  }

  createInfos(text) {
    const infos = document.createElement('div');
    infos.style.color = 'white';
    infos.style.fontSize = '14px';
    infos.textContent = text;
    return infos;
  }

  createProgress(max, value = 0) {
    const progress = document.createElement('progress')
    progress.style.width = '50%';
    progress.max = max;
    progress.value = value;
    return progress;
  }

  setProgress(value) {
    this.progress.value = value;
  }

  setInfos(text) {
    this.infos.textContent = text;
  }

  destroy() {
    document.body.removeChild(this.container);
    if (!this.bodyOverflowHidden) document.body.style.overflow = '';
  }
}
