import { Controller } from "stimulus";
import { getCompanies } from "../utils";

let companies = null;

export default class extends Controller {
  static targets = ["company", "department"];

  companies = [];

  connect() {
    if (!companies) {
      getCompanies("dept=true", (response) => {
        companies = [
          {
            name: this.companyTarget.dataset.placeholder || "请选择公司",
            code: "",
            departments: [],
          },
          ...response.map((item) => {
            item.departments = [
              {
                name: this.departmentTarget.dataset.placeholder || '请选择部门',
                code: "",
              },
              ...item.departments,
            ];
            return item;
          }),
        ];
        this.init();
      });
    } else {
      this.init();
    }
  }

  init() {
    const companyCode = this.companyTarget.dataset.defaultValue;
    const companyOptions = companies.map((item) => {
      const option = document.createElement("option");
      option.value = item.code;
      option.textContent = item.name;
      option.selected = item.code === companyCode;
      return option;
    });
    this.companyTarget.innerHTML = "";
    this.companyTarget.append(...companyOptions);

    const departmentCode = this.departmentTarget.dataset.defaultValue;
    const currentCompany = companies.find(
      (item) => item.code === companyCode
    );
    const departmentOptions = currentCompany
      ? currentCompany.departments.map((item) => {
          const option = document.createElement("option");
          option.value = item.code;
          option.textContent = item.name;
          option.selected = item.code === departmentCode;
          return option;
        })
      : [];
    this.departmentTarget.innerHTML = "";
    this.departmentTarget.append(...departmentOptions);

    this.companyTarget.onchange = (e) => {
      const currentCompany = companies.find(
        (item) => item.code === e.target.value
      );
      const departmentOptions = currentCompany
        ? currentCompany.departments.map((item) => {
            const option = document.createElement("option");
            option.value = item.code;
            option.textContent = item.name;
            return option;
          })
        : [];
      this.departmentTarget.innerHTML = "";
      this.departmentTarget.append(...departmentOptions);
    };
  }

  disconnect() {}
}
