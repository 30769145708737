import { Controller } from "stimulus";
import { setFormFieldStatus, checkMobile } from "../utils";

export default class extends Controller {
  connect() {}

  checkMobileIsExisted = (e) => {
    const element = e.target;
    checkMobile(element.value, (response) => {
      if (response && response.code !== "existed") {
        setFormFieldStatus(element, "error", "手机号还未注册");
      }
    });
  };

  disconnect() {}
}
