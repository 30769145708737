import { getMetaContent } from "./dom";

export function getUploadPath() {
  return getMetaContent("upload-path", "未获取到上传文件地址");
}

export function getCompaniesPath() {
  return getMetaContent("companies-path", "未获取到公司列表请求地址");
}

export function getDepartmentsPath() {
  return getMetaContent("departments-path", "未获取到部门列表请求地址");
}

export function getSendCaptchaPath() {
  return getMetaContent("send-captcha-path", "未获取到发送验证码请求地址");
}

export function getCheckMobilePath() {
  return getMetaContent("check-mobile-path", "未获取到检查手机号请求地址");
}

export function getCheckEmailPath() {
  return getMetaContent("check-email-path", "未获取到检查邮箱请求地址");
}

export function getStaffsPath() {
  return getMetaContent("staffs-path", "未获取到查询员工的请求地址");
}

export function getProjectItemPath() {
  return getMetaContent("project-item-path", "未获取到查询项目信息的请求地址");
}

export function getCheckPartnerExistPath() {
  return getMetaContent("check-partner-exist-path", "未获取到检查合作商是否存在的请求地址");
}

export function getStaffInvitationPath() {
  return getMetaContent("staff-invitation-path", "未获取到获取员工邀请码的请求地址");
}
