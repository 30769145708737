export function strToAmount(str = '') {
  const strs = str.replace(/[^\d.]/g, "").split('.');
  const part1 = strs[0].replace(/^\D+/, '');
  const part2 = strs.length > 1 ? strs.slice(1).join('').replace(/^(\d{0,2}).*/, '$1') : '';
  return (part1 || '0') + '.' + (part2 || '0');
}

export function strToNumber(str = '') {
  return str.replace(/[\D]/g, "");
}
