import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["companyWrapper", "departmentWrapper"];

  companySelectorController = null;
  departmentSelectorController = null;

  connect() {
    this.companyWrapperTarget.onselectchange = value => {
      if (!this.companySelectorController) {
        this.companySelectorController = this.application.getControllerForElementAndIdentifier(this.companyWrapperTarget, 'form-select');
      }
      this.onCompanyChange(value);
    };
    this.departmentWrapperTarget.onselectchange = value => {
      if (!this.departmentSelectorController) {
        this.departmentSelectorController = this.application.getControllerForElementAndIdentifier(this.departmentWrapperTarget, 'form-select');
      }
    };
  }

  onCompanyChange = (value) => {
    if (this.companySelectorController) {
      const currCompanyOption = this.companySelectorController.optionsValue.find(item => item.value === value);
      const currDepartmentOptions = currCompanyOption ? currCompanyOption.children : [];
      if (this.departmentSelectorController) {
        this.departmentSelectorController.optionsValue = currDepartmentOptions;
        this.departmentSelectorController.storeValue = [];
        this.departmentSelectorController.initSearcher();
      }
    }
  }

  disconnect() {}
}
