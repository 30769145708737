import { v4 as uuidv4 } from 'uuid';
import { myAjax } from "./ujs";
import {
  getCompaniesPath,
  getDepartmentsPath,
  getSendCaptchaPath,
  getCheckMobilePath,
  getCheckEmailPath,
  getStaffsPath,
  getProjectItemPath,
  getCheckPartnerExistPath,
} from "./config";
import { FileUploadProgress, file_h_size, getFileMd5, getStaffInvitationPath } from ".";

export function obj2SearchParams(obj) {
  return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
}

export function uploadFile({ file, success = null, error = null, showProgress = false }) {
  getFileMd5({
    file,
    success: (md5, fileChunks) => {
      myAjax({
        type: 'GET',
        url: '/files/' + md5,
        data: `file_name=${encodeURIComponent(file.name)}`,
        success: (response) => {
          if (response.file_url) {
            if (success) success(response);
            return;
          }
          const uuid = uuidv4();
          const fileChunk = fileChunks.shift();
          let uploaded = 0, uploadStatus = 'uploading';
          if (fileChunk) {
            function sendChunk(chunk, [startRange, endRange]) {
              myAjax({
                type: "POST",
                url: '/files/upload',
                data: chunk,
                beforeSend: (request) => {
                  request.setRequestHeader('Content-Type', file.type || 'application/octet-stream');
                  request.setRequestHeader('Content-Disposition', `attachment; filename="${encodeURIComponent(file.name)}"`);
                  request.setRequestHeader('X-Content-Range', `bytes ${startRange}-${endRange}/${file.size}`);
                  request.setRequestHeader('Session-ID', uuid);
                },
                success: (response) => {
                  const fileChunk = fileChunks.shift();
                  if (fileChunk) {
                    uploaded = endRange + 1;
                    sendChunk(fileChunk, [endRange + 1, endRange + fileChunk.size]);
                  } else {
                    uploadStatus = 'done';
                    uploaded = file.size;
                    if (success) success(response);
                  }
                },
                error: (response) => {
                  uploadStatus = 'error';
                  if (error) error(response);
                },
              });
            }
            sendChunk(fileChunk, [0, fileChunk.size - 1]);
          }
          if (showProgress) {
            const fileUploadprogress = new FileUploadProgress({
              title: file.name,
              max: file.size,
              value: uploaded,
              infos: `大小: ${file_h_size(file.size)}，已上传: ${uploaded}`,
            });
            const drawProgress = () => {
              if (uploadStatus == 'uploading') {
                fileUploadprogress.setProgress(uploaded);
                fileUploadprogress.setInfos(`大小: ${file_h_size(file.size)}，已上传: ${file_h_size(uploaded)}`);
                requestAnimationFrame(drawProgress);
              }
              if (uploadStatus == 'done') {
                fileUploadprogress.setProgress(file.size);
                fileUploadprogress.setInfos(`大小: ${file_h_size(file.size)}，上传完成`);
                setTimeout(() => {
                  fileUploadprogress.destroy();
                }, 500);
              }
              if (uploadStatus == 'error') {
                fileUploadprogress.destroy();
                alert('上传出错');
              }
            };
            requestAnimationFrame(drawProgress);
          }
        },
      });
    },
  })
}

export function getCompanies(data = null, success = null, error = null) {
  const path = getCompaniesPath();
  myAjax({
    type: "GET",
    url: path,
    data: data,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function getDepartments(data = null, success = null, error = null) {
  const path = getDepartmentsPath();
  myAjax({
    type: "GET",
    url: path,
    data: data,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function sendCaptcha(data, success = null, error = null) {
  const path = getSendCaptchaPath();
  myAjax({
    type: "POST",
    url: path,
    data: data,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function checkMobile(mobile, success = null, error = null) {
  const path = getCheckMobilePath();
  myAjax({
    type: "GET",
    url: path,
    data: `mobile=${mobile}`,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function checkEmail(email, success = null, error = null) {
  const path = getCheckEmailPath();
  myAjax({
    type: "GET",
    url: path,
    data: `email=${email}`,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function getStaffs(data, success = null, error = null) {
  const path = getStaffsPath();
  myAjax({
    type: "GET",
    url: path,
    data,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function getProjectItem(data, success = null, error = null) {
  const path = getProjectItemPath();
  myAjax({
    type: "GET",
    url: path,
    data,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function checkPartnerExist(data, success = null, error = null) {
  const path = getCheckPartnerExistPath();
  myAjax({
    type: "GET",
    url: path,
    data,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}

export function getStaffInvitation(data, success = null, error = null) {
  const path = getStaffInvitationPath();
  myAjax({
    type: "GET",
    url: path,
    data,
    success: (response) => {
      if (success) success(response);
    },
    error: (response) => {
      if (error) error(response);
    },
  });
}
