import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["type", "code"];

  connect() {
    this.typeChange();
  }

  typeChange = () => {
    const codeController = this.application.getControllerForElementAndIdentifier(this.codeTarget, 'form-select');
    if (this.typeTarget.value === '0') {
      if (codeController) codeController.disabled = false;
      this.codeTarget.classList.remove('d-none');
    } else {
      if (codeController) {
        codeController.disabled = true;
        codeController.storeInputTarget.value = '';
      }
      this.codeTarget.classList.add('d-none');
    }
    if (codeController) codeController.storeInputChange();
  }

  disconnect() {}
}
