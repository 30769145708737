import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showNum"];
  static values = {
    href: String,
    countDown: Number,
  };

  countDownId = null;

  connect() {
    if (this.countDownValue && this.hrefValue) {
      let num = this.countDownValue;
      this.countDownId = setInterval(() => {
        num--;
        if (this.hasShowNumTarget) {
          this.showNumTarget.textContent = num.toString();
        }
        if (num <= 0) {
          clearInterval(this.countDownId);
          location.replace(this.hrefValue);
        }
      }, 1000);
    }
  }

  disconnect() {
    clearInterval(this.countDownId);
  }
}
