import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "editor"]

  connect() {}

  showEditor() {
    if (this.hasContentTarget && this.hasEditorTarget) {
      this.contentTarget.classList.add('d-none');
      this.editorTarget.classList.remove('d-none');
    }
  }

  hideEditor() {
    if (this.hasContentTarget && this.hasEditorTarget) {
      this.contentTarget.classList.remove('d-none');
      this.editorTarget.classList.add('d-none');
    }
  }

  disconnect() {}
}