import { Controller } from "stimulus";
import { Modal } from "@coreui/coreui";
import { findParent, alert } from "../utils";

export default class extends Controller {
  static targets = ["listModal", "bankItem", "searchLink", "queryItem", "addFormField", "addFormLink", "bankList"];

  modal = null;
  currentRow = null;

  connect() {
    this.listModalTarget.style.background = "rgba(0, 0, 21, .5)";
    this.modal = new Modal(this.listModalTarget, {
      backdrop: false,
    });
  }

  showSelector = (e) => {
    this.cancleAllSelectedBankItem();
    this.currentRow = findParent(e.target, "tr");
    this.modal.show();
  };

  hideSelector = () => {
    this.modal.hide();
  };

  selectBankItem = (e) => {
    this.cancleAllSelectedBankItem();
    if (e.target.tagName.toLowerCase() === 'tr') {
      e.target.classList.add("bg-info");
    } else {
      findParent(e.target, 'tr').classList.add("bg-info");
    }
  };

  confirm = () => {
    const selectedBankItem = this.bankItemTargets.find(bankItem => bankItem.classList.contains('bg-info'));
    if (!selectedBankItem) {
      alert({
        title: '请选择一个银行',
        icon: 'warning',
      });
      return;
    }
    const id = selectedBankItem.dataset.id;
    const bankName = selectedBankItem.dataset.bankName;
    const bankTypeName = selectedBankItem.dataset.bankTypeName;
    if (this.currentRow) {
      this.currentRow.querySelector("input[name*=bank_id]").value = id;
      this.currentRow.querySelector("input[name*=bank_name]").value = bankName;
      this.currentRow.querySelector("input[name*=bank_type_name]").value = bankTypeName;
    }
    this.hideSelector();
  };

  cancleAllSelectedBankItem = () => {
    this.bankItemTargets.forEach((bankItem) => {
      bankItem.classList.remove("bg-info");
    });
  };

  submitQuery = (e) => {
    if (e instanceof KeyboardEvent) {
      if (!['enter', 'numpadenter'].includes(e.code.toLowerCase())) return;
      e.preventDefault();
    }
    const uri = new URL(this.searchLinkTarget.href);
    this.queryItemTargets.forEach(item => {
      const key = item.dataset.name;
      const value = item.value;
      uri.searchParams.set(key, value);
    });
    this.searchLinkTarget.href = uri.toString();
    this.searchLinkTarget.click();
  };

  clearQuery = (e) => {
    e.preventDefault();
    const uri = new URL(this.searchLinkTarget.href);
    this.queryItemTargets.forEach(item => {
      item.value = '';
      const key = item.dataset.name;
      uri.searchParams.delete(key);
    });
    this.searchLinkTarget.href = uri.toString();
    this.searchLinkTarget.click();
  };

  submitAddForm = (e) => {
    const uri = new URL(this.addFormLinkTarget.href);
    this.addFormFieldTargets.forEach(item => {
      const key = item.dataset.name;
      const value = item.value;
      uri.searchParams.set(key, value);
    });
    this.addFormLinkTarget.href = uri.toString();
    this.addFormLinkTarget.click();
  };

  submitAddFormSuccess = (xhr) => {
    const [data] = xhr.detail;
    const uri = new URL(this.searchLinkTarget.href);
    this.queryItemTargets.forEach(item => {
      const key = item.dataset.name;
      if (['bank_name', 'bank_type_name'].includes(key)) {
        item.value = data[key];
        uri.searchParams.set(key, data[key]);
      } else {
        item.value = '';
        uri.searchParams.delete(key);
      }
    });
    this.searchLinkTarget.src = uri.toString();
    this.bankListTarget.removeAttribute('src');
    this.bankListTarget.setAttribute('src', uri.toString());
  };

  disconnect() {}
}
