import { Controller } from "stimulus";
import { Modal } from "@coreui/coreui";

export default class extends Controller {
  static values = {
    autoShow: Boolean,
    refreshFrameId: String,
  };

  modal = null;
  turboFrameElem = null;

  connect() {
    this.element.style.background = "rgba(0, 0, 21, .5)";
    this.turboFrameElem = this.element.parentElement;
    this.modal = new Modal(this.element, {
      backdrop: false,
    });
    if (this.autoShowValue) this.modal.show();
    this.element.addEventListener("hide.coreui.modal", this.hideHandle);
    this.element.addEventListener("hidden.coreui.modal", this.hiddenHandle);
  }

  show = () => {
    this.modal.show();
  };

  hide = () => {
    this.modal.hide();
  };

  hideHandle = (e) => {
    if (e.target === this.element) {
      this.turboFrameElem.removeAttribute("src");
    }
  };

  hiddenHandle = (e) => {
    if (e.target === this.element) {
      this.element.remove();
    }
  };

  formSubmitSuccessHide = (e) => {
    if (e.detail.success) {
      this.modal.hide();
      if (this.refreshFrameIdValue) {
        const elem = document.getElementById(this.refreshFrameIdValue);
        if (elem) {
          const frameController = this.application.getControllerForElementAndIdentifier(elem, 'turbo-frame');
          if (frameController) frameController.refresh();
        }
      }
    }
  };

  disconnect() {
    this.element.removeEventListener("hide.coreui.modal", this.hideHandle);
    this.element.removeEventListener("hidden.coreui.modal", this.hiddenHandle);
    this.turboFrameElem.removeAttribute("src");
  }
}
