import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loading"]

  connect() {
    document.addEventListener('ajax:send', this.showLoading);
    document.addEventListener('ajax:complete', this.hideLoading);
    document.addEventListener('turbo:before-stream-render', this.hideLoading);
    document.addEventListener('turbo:render', this.hideLoading);
    document.addEventListener('turbo:submit-start', this.showLoading);
    document.addEventListener('turbo:submit-end', this.hideLoading);
    document.addEventListener('turbo:before-fetch-request', this.showLoading);
    document.addEventListener('turbo:before-fetch-response', this.hideLoading);
  }

  showLoading = (e) => {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.remove('d-none');
    }
  }

  hideLoading = (e) => {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.add('d-none');
    }
  }

  disconnect() {
    document.removeEventListener('ajax:send', this.showLoading);
    document.removeEventListener('ajax:complete', this.hideLoading);
    document.removeEventListener('turbo:before-stream-render', this.hideLoading);
    document.removeEventListener('turbo:render', this.hideLoading);
    document.removeEventListener('turbo:submit-start', this.showLoading);
    document.removeEventListener('turbo:submit-end', this.hideLoading);
    document.removeEventListener('turbo:before-fetch-request', this.showLoading);
    document.removeEventListener('turbo:before-fetch-response', this.hideLoading);
  }
}