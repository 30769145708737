import { Controller } from "stimulus";
import { getStaffInvitation } from "../utils";

export default class extends Controller {
  static targets = [
    "invitationIdInput",
    "code",
    "codeInput",
    "staffName",
    "companyName",
    "departmentName",
  ];
  static values = {
    partnerId: String,
    defaultShow: String,
  };

  connect() { }

  searchInvitation() {
    const value = this.codeInputTarget.value.trim();
    if (value) {
      getStaffInvitation(`code=${value}&partnr_id=${this.partnerIdValue}`, (data) => {
        this.invitationIdInputTarget.value = data.id;
        this.codeTarget.innerHTML = data.code;
        this.staffNameTarget.innerHTML = data.staff_name;
        this.companyNameTarget.innerHTML = data.company_name;
        this.departmentNameTarget.innerHTML = data.department_name;
      });
    }
  }

  disconnect() { }
}
