import { Controller } from "stimulus";
import { _validator, setFormFieldStatus, strToAmount, strToNumber } from "../utils";

export default class extends Controller {
  connect() {}

  ignoreNonAmount = (e) => {
    e.target.value = strToAmount(e.target.value);
  }

  ignoreNonNumber = (e) => {
    e.target.value = strToNumber(e.target.value);
  };

  ignoreWhiteSpace = (e) => {
    const value = e.target.value;
    e.target.value = value.replace(/\s/g, "");
  };

  validate = (e) => {
    const element = e.target;
    const value = element.value;
    const type = element.dataset.validateType;
    const fieldName = element.dataset.fieldName;
    let error = null;
    if (type) {
      type.split(",").some((key) => {
        if (_validator[key]) {
          const result = _validator[key](value, fieldName);
          if (result !== true) {
            error = result;
            return true;
          }
        } else if (/^confirmPassword#/.test(key)) {
          const [vk, pid] = key.split("#");
          if (_validator[vk]) {
            const result = _validator[vk](value, pid);
            if (result !== true) {
              error = result;
              return true;
            }
          }
        }
      });
    }
    if (error) {
      e.stopImmediatePropagation();
      setFormFieldStatus(element, "error", error.message);
    } else {
      setFormFieldStatus(element, "success");
    }
  };

  disconnect() {}
}
