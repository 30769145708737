import { Controller } from "stimulus";
import { myAjax, htmlStr2Dom, findParent } from "../utils";

export default class extends Controller {
  static targets = [
    "list",
    "attachment",
    "attachmentListModal",
    "attachmentsLink",
  ];
  static values = {
    templateUrl: String,
  };

  connect() {
    let companyCode = '';
    const companyCodeElem = document.querySelector('#task_order_company_code');
    companyCodeElem.addEventListener('change', e => {
      if (companyCode !== e.target.value) {
        companyCode = e.target.value;
        [...this.listTarget.children].forEach(item => {
          this.listTarget.removeChild(item);
        });
      }
    });
  }

  getCurrentAttachmentIds = () => {
    return Array.from(
      this.element.querySelectorAll(
        "input[type='hidden'][name*='[replace_id]']"
      )
    )
      .filter((elem) => {
        const destroy = elem.parentNode.querySelector(
          "input[type='checkbox'][name*='[_destroy]']"
        );
        return !destroy.checked;
      })
      .map((elem) => elem.value);
  };

  add = () => {
    const companyCode = document.querySelector('#task_order_company_code').value;
    const url = new URL(this.templateUrlValue, window.location.origin);
    url.searchParams.append('company_code', companyCode);
    myAjax({
      url: url.toString(),
      type: "GET",
      success: (_data, _status, response) => {
        this.listTarget.append(htmlStr2Dom(response.responseText));
      },
    });
  };

  replace = () => {
    const href = this.attachmentsLinkTarget.href;
    const uri = new URL(href);
    uri.searchParams.delete("attachment_ids[]");
    this.getCurrentAttachmentIds().forEach(item => {
      uri.searchParams.append("attachment_ids[]", item);
    });
    this.attachmentsLinkTarget.href = uri.toString();
    this.attachmentsLinkTarget.click();
  };

  addByAttachmentIds = (attachmentIds) => {
    if (
      Array.isArray(attachmentIds) &&
      attachmentIds.length > 0 &&
      this.templateUrlValue &&
      this.hasListTarget
    ) {
      myAjax({
        url: this.templateUrlValue,
        type: "GET",
        data: attachmentIds.map((id) => `attachment_ids[]=${id}`).join("&"),
        success: (_data, _status, response) => {
          this.listTarget.append(htmlStr2Dom(response.responseText));
        },
      });
    }
  };

  removeByAttachmentId = (attachmentId) => {
    Array.from(
      this.element.querySelectorAll(
        `input[type='hidden'][name*='[replace_id]'][value='${attachmentId}']`
      )
    )
      .filter((elem) => {
        const destroy = elem.parentNode.querySelector(
          "input[type='checkbox'][name*='[_destroy]']"
        );
        return !destroy.checked;
      })
      .forEach((elem) => {
        const rowElem = findParent(elem, "tr", this.element);
        if (rowElem) {
          const idElem = elem.parentNode.querySelector(
            "input[type='hidden'][name*='[id]']"
          );
          if (idElem.value) {
            const destroy = elem.parentNode.querySelector(
              "input[type='checkbox'][name*='[_destroy]']"
            );
            destroy.checked = true;
            rowElem.style.display = "none";
          } else {
            rowElem.remove();
          }
        }
      });
  };

  confirm = () => {
    const checkIds = this.attachmentTargets
      .filter((attachment) => attachment.checked)
      .map((attachment) => attachment.value);
    const attachmentIds = this.getCurrentAttachmentIds();
    const removeIds = attachmentIds.filter((item) => !checkIds.includes(item));
    const newIds = checkIds.filter((item) => !attachmentIds.includes(item));
    removeIds.forEach((id) => this.removeByAttachmentId(id));
    this.addByAttachmentIds(newIds);
    this.hideTaskOrderListModal();
  };

  cancel = () => {
    this.hideTaskOrderListModal();
  };

  hideTaskOrderListModal = () => {
    const modal = this.application.getControllerForElementAndIdentifier(
      this.attachmentListModalTarget,
      "turbo-modal"
    );
    modal.hide();
  };

  disconnect() {}
}
