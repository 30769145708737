import { Controller } from "stimulus";
import { alert } from '../utils';

export default class extends Controller {
  static targets = ['text']

  connect() { }

  copy = () => {
    window.navigator.clipboard.writeText(this.textTarget.textContent.trim());
    alert({ title: '复制成功！', icon: 'success' });
  }

  disconnect() { }
}
