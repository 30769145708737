import { Controller } from "stimulus";
import { setFormFieldStatus, checkMobile, checkEmail } from "../utils";

export default class extends Controller {
  connect() {}

  checkMobileIsExisted = (e) => {
    const element = e.target;
    checkMobile(element.value, (response) => {
      if (response && response.code !== "none") {
        setFormFieldStatus(element, "error", response.message);
      }
    });
  };

  checkEmailIsExisted = (e) => {
    const element = e.target;
    checkEmail(element.value, (response) => {
      if (response && response.code !== "none") {
        setFormFieldStatus(element, "error", response.message);
      }
    });
  };

  disconnect() {}
}
