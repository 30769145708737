import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-start", this.startLoading);
    this.element.addEventListener("turbo:submit-end", this.stopLoading);
  }

  startLoading = (e) => {
    if (e.target === this.element) {
      this.element.classList.add("loading");
    }
  };

  stopLoading = (e) => {
    if (e.target === this.element) {
      this.element.classList.remove("loading");
    }
  };

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", this.startLoading);
    this.element.removeEventListener("turbo:submit-end", this.stopLoading);
  }
}
