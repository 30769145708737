import { Controller } from "stimulus";
import { getProjectItem } from "../utils";

export default class extends Controller {
  static targets = ["code", "name", "paname"];

  connect() {}

  checkCode = () => {
    if (this.hasCodeTarget) {
      getProjectItem(
        `project_code=${this.codeTarget.value}`,
        (data) => {
          if (this.hasNameTarget) {
            this.nameTarget.textContent = data.name;
          }
          if (this.hasPanameTarget) {
            this.panameTarget.textContent = `${data.paname || ''} - ${data.pacode || ''}`;
          }
        },
        () => {
          if (this.hasNameTarget) {
            this.nameTarget.textContent = "";
          }
          if (this.hasPanameTarget) {
            this.panameTarget.textContent = "";
          }
        }
      );
    }
  };

  disconnect() {}
}
