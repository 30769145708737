import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submitBtn", "allCheckbox", "idCheckBox"];

  connect() { }

  toggleCheckedAll(e) {
    const checked = e.target.checked;
    this.idCheckBoxTargets.forEach(item => {
      item.checked = checked;
    });
    this.submitBtnTarget.disabled = !this.idCheckBoxTargets.some(item => item.checked);
  }

  setAllCheckbox() {
    const checked = this.idCheckBoxTargets.every(item => item.checked);
    this.allCheckboxTarget.checked = checked;
    this.submitBtnTarget.disabled = !this.idCheckBoxTargets.some(item => item.checked);
  }

  disconnect() { }
}
