import { Controller } from "stimulus";
import { debounce } from "lodash-es";
import { getStaffs } from "../utils";

export default class extends Controller {
  static targets = [
    "searchWrapper",
    "searchInput",
    "searchText",
    "storeInput",
    "optionList",
    "placeholder",
  ];
  static values = {
    options: Array,
    showSearch: Boolean,
    multiple: Boolean,
    placeholder: String,
    type: String,
    where: Object,
    store: Array,
    disabled: Boolean,
    searchKeywords: String,
  };

  optionElems = [];
  labelElems = [];

  setPosAnmId = null

  connect() {
    this.storeInputTarget.addEventListener('change', this.storeInputChange);
    this.initSearcher();
    if (Array.isArray(this.optionsValue)) {
      this.generalOptions();
    } else {
      if (this.typeValue === "staff") {
        this.fetchStaffOptions(this.storeInputTarget.value.trim());
      }
    }
  }

  // 类型发生变化
  typeValueChanged() {
    if (this.typeValue === "staff") {
      this.fetchStaffOptions(this.storeInputTarget.value.trim());
    }
  }

  // 选项发生变化
  optionsValueChanged() {
    this.generalOptions();
  }

  // 值发生变化
  storeValueChanged() {
    this.storeInputTarget.value = this.storeValue.join(',');
    this.generalLabelElems();
    this.refreshOptions();
    this.setPlaceholderStatus();
    if (this.typeValue === 'staff') {
      const event = new Event('staffchange');
      event.selectMode = 'staff';
      event.selectOption = this.optionsValue.find(item => item.value === this.storeInputTarget.value);
      this.storeInputTarget.dispatchEvent(event);
    }
    if (this.element.onselectchange)
      this.element.onselectchange(this.storeInputTarget.value);
  }

  // 搜索关键词发生变化
  searchKeywordsValueChanged(curValue) {
    this.searchTextTarget.textContent = curValue;
    this.setPlaceholderStatus();
    if (this.multipleValue) {
      const width = this.searchTextTarget.offsetWidth || 4;
      this.searchWrapperTarget.style.width = `${width}px`;
    }
    if (this.typeValue === "staff") {
      this.fetchStaffOptions(curValue.trim());
    } else {
      this.refreshOptions();
    }
  }

  getStoreValues = () => {
    const str = this.storeInputTarget.value.trim().replace(/,+/, ',').replace(/(^,)|(,$)/, '');
    const allValues = this.optionsValue.map(item => item.value);
    return str.split(',').filter(item => allValues.includes(item));
  }

  storeInputChange = () => {
    this.storeValue = this.getStoreValues();
  }

  initSearcher() {
    this.searchWrapperTarget.classList.add("d-none");
    this.searchWrapperTarget.style.width = this.multipleValue
      ? "4px"
      : "calc(100% - 0.75rem)";
  }

  enabledSelector = (e) => {
    if (this.disabledValue) return;
    e.stopPropagation();
    if (this.showSearchValue) {
      this.showSearchWrapper();
      this.searchInputTarget.focus();
    }
    this.showOptionList();
  }

  setPlaceholderStatus() {
    if (this.storeValue.length > 0 || this.searchKeywordsValue) {
      this.placeholderTarget.classList.add('d-none');
    } else {
      this.placeholderTarget.classList.remove('d-none');
    }
  }

  searchInputChange = () => {
    const curValue = this.searchInputTarget.value;
    this.searchKeywordsValue = curValue;
  };

  searchInputFocus = (e) => {
    if (this.disabledValue) return;
    if (!this.multipleValue) {
      const label = this.element.querySelector("[data-type=option-label]");
      if (label) label.classList.add("d-none");
      this.searchInputTarget.placeholder = label
        ? label.textContent
        : this.placeholderValue;
    }
  }

  searchInputBlur() {
    this.searchInputTarget.value = "";
    this.searchTextTarget.textContent = "";
    this.searchInputTarget.dispatchEvent(new Event('input'));
    if (this.multipleValue) {
      this.searchWrapperTarget.style.width = "4px";
    } else {
      const label = this.element.querySelector("[data-type=option-label]");
      if (label) {
        label.classList.remove("d-none");
      }
    }
    this.optionElems.forEach((elem) => {
      elem.dataset.hidden = "false";
    });
    this.hideSearchWrapper();
    this.hideOptionList();
  }

  stopSearchInputBlur(e) {
    if (e && e.preventDefault) e.preventDefault();
    else window.event.returnValue = false;
    return false;
  }

  optionItemClick = (e) => {
    if (this.disabledValue) return;
    this.changeSeletedValue(e.target.dataset.value);
    if (!this.multipleValue) {
      e.stopImmediatePropagation();
      this.searchInputTarget.placeholder = "";
      this.searchInputTarget.blur();
      this.hideOptionList();
    }
    if (this.searchInputTarget.value) {
      this.searchInputTarget.value = "";
      this.searchInputChange();
    }
  };

  createOptionElem(option) {
    const elem = document.createElement("div");
    elem.className = "form-select-option-item";
    elem.dataset.hidden = "false";
    elem.dataset.value = option.value;
    elem.dataset.label = option.label;
    elem.textContent = option.label;
    elem.onclick = this.optionItemClick;
    return elem;
  }

  createLabelElem(option) {
    const elem = document.createElement("span");
    if (this.multipleValue) {
      elem.className = "badge bg-light text-dark mx-1 mb-1 d-inline-flex align-items-center p-1";
    } else {
      elem.className = "d-flex align-items-center text-nowrap overflow-hidden";
      elem.style.width = "calc(100% - 16px)";
    }
    elem.dataset.type = "option-label";
    elem.dataset.value = option.value;
    elem.dataset.label = option.label;
    elem.textContent = option.label;
    if (this.multipleValue) {
      const closeElem = document.createElement("span");
      closeElem.textContent = "x";
      closeElem.style.marginLeft = "5px";
      closeElem.style.cursor = "pointer";
      closeElem.onclick = () => this.changeSeletedValue(elem.dataset.value);
      elem.appendChild(closeElem);
    }
    return elem;
  }

  generalLabelElems() {
    this.element
      .querySelectorAll("[data-type=option-label]")
      .forEach((elem) => {
        this.element.removeChild(elem);
      });
    const labelElems = this.optionsValue
      .filter((item) => this.storeValue.includes(item.value.toString()))
      .map((item) => this.createLabelElem(item));
    this.element.prepend(...labelElems);
  }

  changeSeletedValue(value) {
    if (this.multipleValue) {
      if (this.storeValue.includes(value)) {
        this.storeValue = this.storeValue.filter(item => item !== value);
      } else {
        this.storeValue = [...this.storeValue, value];
      }
    } else {
      this.storeValue = [value];
    }
  }

  generalOptions() {
    this.optionElems = [];
    this.optionsValue.forEach((item) => {
      this.optionElems.push(this.createOptionElem(item));
    });
    this.optionListTarget.innerHTML = "";
    this.optionListTarget.append(...this.optionElems);
    this.refreshOptions();
  }

  refreshOptions = debounce(() => {
    this.optionElems.forEach((elem) => {
      const label = elem.dataset.label;
      const value = elem.dataset.value;
      const isSelected = this.storeValue.includes(elem.dataset.value);
      const keywords = this.searchKeywordsValue;
      const inKeywords = !keywords || label.includes(keywords) || value.includes(keywords);
      elem.dataset.selected = isSelected.toString();
      elem.dataset.hidden = (!inKeywords).toString();
    });
  }, 10);

  showOptionList = () => {
    this.element.dataset.active = "true";
    const callback = () => {
      this.setOptionListPosition();
      this.setPosAnmId = requestAnimationFrame(callback);
    };
    callback();
  };

  hideOptionList = () => {
    this.element.dataset.active = "false";
    if (this.setPosAnmId) cancelAnimationFrame(this.setPosAnmId);
  };

  setOptionListPosition = () => {
    if (this.element.dataset.active !== 'true') return;
    const rect = this.element.getBoundingClientRect();
    this.optionListTarget.style.width = `${rect.width}px`;
    this.optionListTarget.style.top = `${rect.top + rect.height}px`;
    this.optionListTarget.style.left = `${rect.left}px`;
  };

  showSearchWrapper = () => {
    this.searchWrapperTarget.classList.remove("d-none");
  };

  hideSearchWrapper = () => {
    this.searchWrapperTarget.classList.add("d-none");
  };

  fetchStaffOptions = debounce((keywords) => {
    if (this.disabledValue || !keywords || keywords.length < 2) return;
    const where = {
      ...this.whereValue,
      keywords,
    }
    const params = Object.keys(where).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(where[key])}`).join('&');
    getStaffs(params, (response) => {
      const data = response.map((item) => ({
        label: `${item.chinese_name} - ${item.clerk_code}`,
        value: item.clerk_code,
        departments: item.departments,
      }));
      this.optionsValue = data;
      this.generalOptions();
    });
  }, 1000);

  disconnect() {
    this.storeInputTarget.removeEventListener('change', this.storeInputChange);
    if (this.setPosAnmId) cancelAnimationFrame(this.setPosAnmId);
  }
}
