import { ajax, fire } from "@rails/ujs";
import { confirm, alert } from "./alert";

export const overrideRailsUjs = (Rails) => {
  Rails.confirm = function (message, element) {
    confirm({
      title: message,
      icon: "warning",
      callback: (ok) => {
        if (ok) {
          element.dataset.confirm = "";
          element.click();
          element.dataset.confirm = message;
        }
      },
    });
    return false;
  };
};

export const startAjaxListener = () => {
  document.addEventListener("ajax:success", (event) => {
    const [_data, _status, _xhr] = event.detail;
  });

  document.addEventListener("ajax:error", (event) => {
    const [data, _status, xhr] = event.detail;
    if (xhr.status === 403) {
      alert({
        title: data.message,
        icon: 'warning',
      });
    }
    if (xhr.status === 422) {
      alert({
        title: data.message,
        icon: 'error',
      });
    }
  });
};

export const myAjax = (options = {}) => {
  ajax({
    ...options,
    beforeSend(...args) {
      if (options.beforeSend) {
        const res = options.beforeSend(...args);
        if (res === false) return false;
      }
      fire(document, "ajax:send", args);
      return true;
    },
    complete(...args) {
      fire(document, "ajax:complete", args);
      if (options.complete) options.complete(...args);
    },
    success(...args) {
      fire(document, "ajax:success", args);
      if (options.success) options.success(...args);
    },
    error(...args) {
      fire(document, "ajax:error", args);
      if (options.error) options.error(...args);
    },
  });
};
