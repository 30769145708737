import { Controller } from "stimulus";
import { myAjax, htmlStr2Dom, findParent } from "../utils";

export default class extends Controller {
  static targets = [
    "list",
    "taskOrder",
    "taskOrderListModal",
    "taskOrdersLink",
  ];
  static values = {
    templateUrl: String,
  };

  connect() {}

  getCurrentTaskOrderIds = () => {
    return Array.from(
      this.element.querySelectorAll(
        "input[type='hidden'][name*='[task_order_id]']"
      )
    )
      .filter((elem) => {
        const destroy = elem.parentNode.querySelector(
          "input[type='checkbox'][name*='[_destroy]']"
        );
        return !destroy.checked;
      })
      .map((elem) => elem.value);
  };

  add = () => {
    const href = this.taskOrdersLinkTarget.href;
    const uri = new URL(href);
    uri.searchParams.delete("task_order_ids[]");
    this.getCurrentTaskOrderIds().forEach(item => {
      uri.searchParams.append("task_order_ids[]", item);
    });
    this.taskOrdersLinkTarget.href = uri.toString();
    this.taskOrdersLinkTarget.click();
  };

  addByTaskOrderIds = (taskOrderIds) => {
    if (
      Array.isArray(taskOrderIds) &&
      taskOrderIds.length > 0 &&
      this.templateUrlValue &&
      this.hasListTarget
    ) {
      myAjax({
        url: this.templateUrlValue,
        type: "GET",
        data: taskOrderIds.map((id) => `task_order_ids[]=${id}`).join("&"),
        success: (_data, _status, response) => {
          this.listTarget.append(htmlStr2Dom(response.responseText));
        },
      });
    }
  };

  removeByTaskOrderId = (taskOrderId) => {
    Array.from(
      this.element.querySelectorAll(
        `input[type='hidden'][name*='[task_order_id]'][value='${taskOrderId}']`
      )
    )
      .filter((elem) => {
        const destroy = elem.parentNode.querySelector(
          "input[type='checkbox'][name*='[_destroy]']"
        );
        return !destroy.checked;
      })
      .forEach((elem) => {
        const rowElem = findParent(elem, "tr", this.element);
        if (rowElem) {
          const idElem = elem.parentNode.querySelector(
            "input[type='hidden'][name*='[id]']"
          );
          if (idElem.value) {
            const destroy = elem.parentNode.querySelector(
              "input[type='checkbox'][name*='[_destroy]']"
            );
            destroy.checked = true;
            rowElem.style.display = "none";
          } else {
            rowElem.remove();
          }
        }
      });
  };

  confirm = () => {
    const checkIds = this.taskOrderTargets
      .filter((taskOrder) => taskOrder.checked)
      .map((taskOrder) => taskOrder.value);
    const taskOrderIds = this.getCurrentTaskOrderIds();
    const removeIds = taskOrderIds.filter((item) => !checkIds.includes(item));
    const newIds = checkIds.filter((item) => !taskOrderIds.includes(item));
    removeIds.forEach((id) => this.removeByTaskOrderId(id));
    this.addByTaskOrderIds(newIds);
    this.hideTaskOrderListModal();
  };

  cancel = () => {
    this.hideTaskOrderListModal();
  };

  hideTaskOrderListModal = () => {
    const modal = this.application.getControllerForElementAndIdentifier(
      this.taskOrderListModalTarget,
      "turbo-modal"
    );
    modal.hide();
  };

  disconnect() {}
}
