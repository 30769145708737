import { Controller } from "stimulus";
import { uploadFile, alert } from "../utils";

export default class extends Controller {
  static targets = ["fileUrlInput", "fileNameInput", "uploadedAtInput"];

  connect() {}

  fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 0) return alert({ title: '文件内容不能为空', icon: 'warning' });
      if (file.size > 1024 * 1024 * 1024) return alert({ title: '文件大小不能超过1G', icon: 'warning' });
      uploadFile({
        file,
        showProgress: true,
        success: (data) => {
          const { file_name, file_url, uploaded_at } = data;
          if (this.hasFileNameInputTarget) this.fileNameInputTarget.value = file_name;
          if (this.hasFileUrlInputTarget) this.fileUrlInputTarget.value = file_url;
          if (this.hasUploadedAtInputTarget) this.uploadedAtInputTarget.value = uploaded_at;
        }
      });
    }
    e.target.value = '';
  };

  clear = (e) => {
    if (this.hasFileNameInputTarget) this.fileNameInputTarget.value = "";
    if (this.hasFileUrlInputTarget) this.fileUrlInputTarget.value = "";
    if (this.hasUploadedAtInputTarget) this.uploadedAtInputTarget.value = "";
  };

  disconnect() {}
}
