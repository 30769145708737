import { Controller } from "stimulus";
import { confirm } from "../utils";

export default class extends Controller {
  static values = {
    eventTargetId: String,
  };

  connect() {}

  remove = () => {
    confirm({
      title: "确定要删除么？",
      icon: "warning",
      callback: (ok) => {
        if (ok) {
          const idElem = this.element.querySelector(
            "input[type='hidden'][name*='[id]']"
          );
          if (idElem.value) {
            const destroy = this.element.querySelector(
              "input[type='checkbox'][name*='[_destroy]']"
            );
            destroy.checked = true;
            this.element.style.display = "none";
          } else {
            this.element.remove();
          }
          this.fireRemoveEvent();
        }
      },
    });
  };

  fireRemoveEvent = () => {
    if (this.eventTargetIdValue) {
      const eventTarget = document.querySelector(`#${this.eventTargetIdValue}`);
      if (eventTarget) {
        eventTarget.dispatchEvent(new Event('formTableRow:remove'));
      }
    }
  };

  disconnect() {}
}
