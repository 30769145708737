import { matches } from "@rails/ujs";

export function findParent(current, selector, final = null) {
  if (current.matches(selector)) return current;
  const parent = current.parentNode;
  if (!parent || parent === final) return null;
  if (!matches(parent, selector)) return findParent(parent, selector, final);
  return parent;
}

export function toggleClass(elem, classNames) {
  if (!Array.isArray(classNames) || classNames.length === 0) return;
  classNames.forEach((className) => {
    if (elem.classList.contains(className)) elem.classList.remove(className);
    else elem.classList.add(className);
  });
}

export function setFormFieldStatus(element, status = null, tips = null) {
  if (!element || !document.documentElement.contains(element))
    throw new Error("需要传入页面中的Element对象");
  const currentElem = element.parentElement.classList.contains("input-group")
    ? element.parentElement
    : element;
  const nextElem = currentElem.nextElementSibling;
  nextElem &&
    (nextElem.classList.contains("valid-feedback") ||
      nextElem.classList.contains("invalid-feedback")) &&
    nextElem.remove();
  switch (status) {
    case "success": {
      currentElem.classList.add("is-valid");
      currentElem.classList.remove("is-invalid");
      if (currentElem !== element) {
        element.classList.add("is-valid");
        element.classList.remove("is-invalid");
      }
      if (tips) {
        const template = document.querySelector(
          "template#form_field_feedback_success"
        );
        const feedback = document.importNode(template.content, true);
        feedback.firstElementChild.lastElementChild.textContent = tips;
        currentElem.insertAdjacentElement(
          "afterend",
          feedback.firstElementChild
        );
      }
      break;
    }
    case "error": {
      currentElem.classList.remove("is-valid");
      currentElem.classList.add("is-invalid");
      if (currentElem !== element) {
        element.classList.remove("is-valid");
        element.classList.add("is-invalid");
      }
      if (tips) {
        const template = document.querySelector(
          "template#form_field_feedback_error"
        );
        const feedback = document.importNode(template.content, true);
        feedback.firstElementChild.lastElementChild.textContent = tips;
        currentElem.insertAdjacentElement(
          "afterend",
          feedback.firstElementChild
        );
      }
      break;
    }
    default: {
      currentElem.classList.remove("is-valid");
      currentElem.classList.remove("is-invalid");
      if (currentElem !== element) {
        element.classList.remove("is-valid");
        element.classList.remove("is-invalid");
      }
    }
  }
}

export function getMetaContent(name, notFound = false) {
  const meta = document.querySelector(`meta[name=${name}]`);
  if (notFound && !meta) throw new Error(notFound);
  return meta && meta.content;
}

export function htmlStr2Dom(str) {
  if (!str) throw new Error("需要传入html字符串");
  const template = document.createElement("template");
  template.innerHTML = str;
  return template.content;
}
