import { Controller } from "stimulus";
import JSONEditor from "jsoneditor";

export default class extends Controller {
  static targets = [
    'input',
    'editor'
  ]

  static values = {
    value: String,
  }

  editor = null

  connect() {
    this.editor = new JSONEditor(this.editorTarget, {
      mode: 'code',
      onChangeText: (text) => {
        this.valueValue = text;
      },
    }, JSON.parse(this.valueValue));
  }

  valueValueChanged(value) {
    this.inputTarget.value = value;
  }

  disconnect() {
    this.editor?.destroy();
  }
}
