import { alert } from './alert';

export const expandTurbo = (Turbo) => {
  window.addEventListener("turbo:before-fetch-response", (e) => {
    if (e.detail && e.detail.fetchResponse) {
      const response = e.detail.fetchResponse.response;
      const status = response.status;
      const turboFrame = response.headers.get("Turbo-Frame");
      const turboFrameUrl = response.headers.get("Turbo-Frame-Url");
      if (turboFrame === "_top" && turboFrameUrl) {
        e.preventDefault();
        Turbo.visit(turboFrameUrl);
      } else if (status === 403) {
        response.json().then(res => {
          alert({
            title: res.message,
            icon: 'warning',
          });
        });
      } else if (status === 422) {
        response.json().then(res => {
          alert({
            title: res.message,
            icon: 'error',
          });
        });
      }
    }
  });
};

export function turboDispatch(eventName, { target, cancelable, detail } = {}) {
  const event = new CustomEvent(eventName, { cancelable, bubbles: true, detail });
  void (target || document.documentElement).dispatchEvent(event);
  return event;
}

window.turboDispatch = turboDispatch;
