import { Controller } from "stimulus";
import { turboDispatch } from 'utils';

export default class extends Controller {
  connect() { }

  reset = () => {
    this.element.reset();
    const form = new FormData(this.element);
    form.forEach((value, key) => {
      const elem = this.element.querySelector(`[name=${key}]`);
      if (elem) turboDispatch('change', { target: elem, detail: { value } });
    });
    this.submit();
  }

  submit = () => {
    turboDispatch('submit', { cancelable: true, target: this.element });
  }

  disconnect() { }
}
