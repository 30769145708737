import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    parentModalId: String,
  }
  
  connect() {
    this.modal = this.application.getControllerForElementAndIdentifier(this.element, 'turbo-modal');
    const parentModalElem = document.querySelector(`#${this.parentModalIdValue}`);
    this.parentModal = this.application.getControllerForElementAndIdentifier(parentModalElem, 'turbo-modal');
  }

  abortSubmitSuccess = (e) => {
    if (e.detail.success) {
      this.parentModal.hide();
    }
  }

  disconnect() { }
}
