import { Controller } from "stimulus";
import validator from "validator";
import { sendCaptcha } from "../utils";

export default class extends Controller {
  static targets = ["phoneNumber", "sendBtn"];

  disabledTimer = null;
  countDown = null;

  connect() {
    this.sendBtnTarget.disabled = !validator.isMobilePhone(
      this.phoneNumberTarget.value,
      "zh-CN"
    );
    this.sendBtnTarget.addEventListener("click", this.send);
  }

  phoneNumberChange = (e) => {
    if (this.countDown > 0) return;
    this.sendBtnTarget.disabled = !validator.isMobilePhone(
      e.target.value,
      "zh-CN"
    );
  };

  send = () => {
    this.sendBtnTarget.disabled = true;
    const phoneNumber = this.phoneNumberTarget.value;
    try {
      if (!validator.isMobilePhone(phoneNumber, ["zh-CN"]))
        throw new Error("手机号格式不正确");
      if (!this.sendBtnTarget.dataset.sceneId)
        throw new Error("场景值不能为空");
      const form = new FormData();
      form.append("phone_number", phoneNumber);
      form.append("scene_id", this.sendBtnTarget.dataset.sceneId);
      sendCaptcha(form, (response) => {
        this.countDown = 60;
        const width = this.sendBtnTarget.offsetWidth;
        this.sendBtnTarget.style.width = `${width}px`;
        this.disabledTimer = setInterval(() => {
          this.sendBtnTarget.textContent = `${this.countDown}s`;
          if (this.countDown <= 0) {
            this.sendBtnTarget.disabled = false;
            this.sendBtnTarget.textContent = "重新获取";
            this.sendBtnTarget.style.width = "";
            clearInterval(this.disabledTimer);
          } else {
            this.countDown--;
          }
        }, 1000);
      }, () => {
        this.sendBtnTarget.disabled = false;
      });
    } catch (err) {
      this.sendBtnTarget.disabled = false;
    }
  };

  disconnect() {
    clearInterval(this.disabledTimer);
  }
}
