import { Controller } from "stimulus";
import { myAjax, htmlStr2Dom } from "../utils";

export default class extends Controller {
  static targets = ["list"];
  static values = {
    templateUrl: String,
  };

  connect() {}

  add = () => {
    if (this.templateUrlValue && this.hasListTarget) {
      const titleInput = document.querySelector('input#partner_title');
      let url = this.templateUrlValue;
      if (titleInput) {
        const title = titleInput.value.trim();
        if (title) url += `?title=${encodeURIComponent(title)}`;
      }
      myAjax({
        url,
        type: "GET",
        success: (_data, _status, response) => {
          this.listTarget.append(htmlStr2Dom(response.responseText));
        },
      });
    }
  };

  disconnect() {}
}
