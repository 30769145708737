import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["company", "department"];

  companies = [];

  connect() {}

  staffChange = (e) => {
    if (e.selectMode !== "staff") return;
    if (e.selectOption) {
      const departments = e.selectOption.departments;
      this.companies = departments.reduce((data, department) => {
        const company = data.find(
          (item) => item.code === department.company_code
        );
        if (company) {
          company.departments.push({
            code: department.department_code,
            name: department.department_name,
          });
        } else {
          data.push({
            code: department.company_code,
            name: department.company_name,
            departments: [
              {
                code: department.department_code,
                name: department.department_name,
              },
            ],
          });
        }
        return data;
      }, []);
    } else {
      this.companies = [];
    }
    this.init();
  };

  init() {
    const companyCode = this.companyTarget.dataset.defaultValue;
    const companyOptions = this.companies.map((item) => {
      const option = document.createElement("option");
      option.value = item.code;
      option.textContent = item.name;
      option.selected = item.code === companyCode;
      return option;
    });
    this.companyTarget.innerHTML = "";
    this.companyTarget.append(...companyOptions);

    const departmentCode = this.departmentTarget.dataset.defaultValue;
    const currentCompany = this.companies.find((item) => item.code === companyCode);
    const departmentOptions = currentCompany
      ? currentCompany.departments.map((item) => {
          const option = document.createElement("option");
          option.value = item.code;
          option.textContent = item.name;
          option.selected = item.code === departmentCode;
          return option;
        })
      : [];
    this.departmentTarget.innerHTML = "";
    this.departmentTarget.append(...departmentOptions);

    this.companyTarget.onchange = (e) => {
      const currentCompany = this.companies.find(
        (item) => item.code === e.target.value
      );
      const departmentOptions = currentCompany
        ? currentCompany.departments.map((item) => {
            const option = document.createElement("option");
            option.value = item.code;
            option.textContent = item.name;
            return option;
          })
        : [];
      this.departmentTarget.innerHTML = "";
      this.departmentTarget.append(...departmentOptions);
    };

    this.companyTarget.dispatchEvent(new Event('change'));
  }

  disconnect() {}
}
