import { Controller } from "stimulus";
import JSONEditor from "jsoneditor";

export default class extends Controller {
  static values = {
    value: String,
  }

  editor = null

  connect() {
    this.editor = new JSONEditor(this.element, {
      mode: 'preview',
      mainMenuBar: false,
    }, JSON.parse(this.valueValue));
  }

  disconnect() {
    this.editor?.destroy();
  }
}
